<template>
	<el-dialog title="运单修改" top="1vh" width="1060px" :visible.sync="is_show_in_Page">
		<el-form  label-width="100px">
			
			<div class="big_tit" style="margin-top:-20px">货物信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="结算方式" style="width:239.5px">
					<el-select class="el_inner_width" @change="settlement_type_change" v-model="form.settlement_type" clearable>
						<el-option label="按吨数" value="1"></el-option>
						<el-option label="按方数" value="2"></el-option>
						<el-option label="按趟" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="货物重量(吨)" style="width:239.5px">
					<el-input class="el_inner_width" type="number" v-model="cargo_weight.num" :placeholder="cargo_weight.is_need_weight_text" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="货物体积(方)" style="width:239.5px">
					<el-input class="el_inner_width" type="number" v-model="cargo_volume.num" :placeholder="cargo_volume.is_need_volume_text" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="距离(公里)" style="width:239.5px">
					<el-input class="el_inner_width" type="number" v-model="distance.num" :placeholder="distance.is_need_distance_text" clearable></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">运费信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物名称" style="width:388px">
					<el-input class="el_inner_width" v-model="form.cargo_name" placeholder="请输入货物名称" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="是否回程" style="width:285px">
					<el-select class="el_inner_width" v-model="form.is_need_return" clearable>
						<el-option label="需要" value="1"></el-option>
						<el-option label="不需要" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="上游运费" style="width:285px">
					<el-input type="number" class="el_inner_width" v-model="form.freight_total" placeholder="必填, 单位(元)" clearable></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">装/卸货地信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="装货地" style="width:388px">
					<el-cascader class="el_inner_width" v-model="form.case_city" placeholder="装货地省市区" :options="cities" filterable></el-cascader>
				</el-form-item>
				<el-form-item class="el_form_item" label="装货地详情" style="width:588px">
					<el-input class="el_inner_width" v-model="form.case_addr" placeholder="请输入装货地详细地址" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="卸货地" style="width:388px">
					<el-cascader class="el_inner_width" v-model="form.aim_city" placeholder="卸货地省市区" :options="cities" filterable></el-cascader>
				</el-form-item>
				<el-form-item class="el_form_item" label="卸货地详情" style="width:588px">
					<el-input class="el_inner_width" v-model="form.aim_addr" placeholder="请输入卸货地详细地址" clearable></el-input>
				</el-form-item>
			</div>
			

			<div class="big_tit">客户 / 司机 / 车辆</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="客户电话">
					<el-input class="el_inner_width" type="number" v-model="shipper.tel" placeholder="必填,请输入货主电话" clearable>
						<el-button @click="shipper_ser" type="success" slot="append">查找</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="客户信息" style="width:588px">
					<el-input class="el_inner_width" v-model="shipper.info" :disabled="true" clearable>
						<el-button @click="shipper_clear" type="success" slot="append">清空</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="司机电话">
					<el-input class="el_inner_width" type="number" v-model="truck_owner.tel" placeholder="必填,请输入司机电话" clearable>
						<el-button @click="truck_owner_ser" type="success" slot="append">查找</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="司机信息" style="width:588px">
					<el-input class="el_inner_width" v-model="truck_owner.name" :disabled="true" clearable>
						<el-button @click="truck_owner_clear" type="success" slot="append">清空</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货车牌号">
					<el-input class="el_inner_width" v-model="truck.truck_plate_num" placeholder="必填,请输入车牌号" clearable>
						<el-button @click="truck_ser" type="success" slot="append">查找</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="车辆信息" style="width:588px">
					<el-input class="el_inner_width" v-model="truck.truck_info_str" :disabled="true" clearable>
						<el-button @click="truck_clear" type="success" slot="append">清空</el-button>
					</el-input>
				</el-form-item>
			</div>


			<div class="big_tit">时间信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="发车时间" style="width:388px">
					<el-date-picker type="datetime" class="el_inner_width" v-model="form.start_time" placeholder="非必填, 如果已发车需要填此项" clearable></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="到货时间" style="width:588px">
					<el-date-picker  type="datetime" class="el_inner_width" v-model="form.arrived_time" placeholder="非必填, 如果已到货需要填此项" clearable></el-date-picker>
				</el-form-item>
			</div>


			<div class="big_tit">其他信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="上游单号" style="width:388px">
					<el-input class="el_inner_width" v-model="form.out_trade_num" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="组别编号" style="width:588px">
					<el-input class="el_inner_width" v-model="form.group1_num" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" style="width:1000px" label="运单备注">
					<el-input class="el_inner_width" v-model="form.mark" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
			</div>


			<div style="margin:30px 0 0 0;text-align:center">
				<el-button type="primary" @click="sub">提交订单</el-button>
				<el-button type="danger" @click="clear">还原数据</el-button>
			</div>
		</el-form>
	</el-dialog>
</template>
<script>
	import {regionData,CodeToText,TextToCode} from 'element-china-area-data'
	export default {
		props:{
			is_show:Number,//是否显示
			tord_info:Object,//运单详情
		},
		data() {
			return {

				//列表是否显示
				is_show_in_Page:false,

				//表单
				form: {
					settlement_type:'',//结算方式
					cargo_name:'',//货物名称
					is_need_return:'',//是否回程
					freight_total:'',//运费
					case_city:[],//装货地省市区
					case_addr:'',//装货地详情
					aim_city:[],//卸货地省市区
					aim_addr:'',//卸货地详情
					start_time:null,//发车时间
					arrived_time:null,//到货时间
					out_trade_num:'',//上游运单编号
					group1_num:'',//组编号1(用户自定义打组)
					mark:'',//备注
				},

				//货物重量
				cargo_weight:{
					num:'',//数值
					is_need_weight:'',//是否必填
					is_need_weight_text:'',//是否必填的placeholder
				},

				//货物体积
				cargo_volume:{
					num:'',//数值
					is_need_volume:'',//是否必填
					is_need_volume_text:'',//是否必填的placeholder
				},

				//运输距离
				distance:{
					num:'',//数值
					is_need_distance:'',//是否必填
					is_need_distance_text:'',//是否必填的placeholder
				},

				//客户
				shipper:{
					tel:'',//客户电话
					info:'',//客户信息
					user_num:'',//客户编号
				},

				//车主(照顾到多数人的思维习惯 页面内叫做司机)
				truck_owner:{
					tel:'',//车主电话
					name:'',//车主姓名
					user_num:'',//车主用户编号
				},

				//车辆
				truck:{
					truck_plate_num:'',//车牌号
					truck_info_str:'',//车辆信息字符串
					truck_plate_num_lock:'',//锁定车牌号
				},

				//城市联动数据
				cities:regionData,
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开页面
					this.is_show_in_Page=true;

					//初始化
					this.clear();
				}
			},
		},
		methods: {

			//提交
			sub(){

				let post_data={
					mod:'truck_tord_real_time',
					ctr:'tord_act_edit_by_admin',
					truck_tord_num:this.tord_info.truck_tord_num,
					set:{
						settlement_type:this.form.settlement_type,
						is_need_return:this.form.is_need_return,
					}
				};

				//货物重量
				if(this.cargo_weight.is_need_weight==1){//必填
					if(!(this.$my.check.is_num(this.cargo_weight.num)&&this.cargo_weight.num>0)){
						this.$my.other.msg({
							type:"warning",
							str:'此路线必填吨数, 请输入货物吨数'
						});
						return;
					}else post_data.set.cargo_weight=this.cargo_weight.num
				}else if(this.cargo_weight.is_need_weight==2){//非必填
					if(this.$my.check.is_num(this.cargo_weight.num)&&this.cargo_weight.num>0){
						post_data.set.cargo_weight=this.cargo_weight.num
					}else post_data.set.cargo_weight=0
				}else{
					this.$my.other.msg({
						type:"warning",
						str:'请刷新后再试'
					});
					return;
				}

				//货物体积
				if(this.cargo_volume.is_need_volume==1){//必填
					if(!(this.$my.check.is_num(this.cargo_volume.num)&&this.cargo_volume.num>0)){
						this.$my.other.msg({
							type:"warning",
							str:'此路线必填体积, 请输入货物体积'
						});
						return;
					}else post_data.set.cargo_volume=this.cargo_volume.num
				}else if(this.cargo_volume.is_need_volume==2){//非必填
					if(this.$my.check.is_num(this.cargo_volume.num)&&this.cargo_volume.num>0){
						post_data.set.cargo_volume=this.cargo_volume.num
					}else post_data.set.cargo_volume=0
				}else{
					this.$my.other.msg({
						type:"warning",
						str:'请刷新后再试'
					});
					return;
				}

				//运输距离
				if(this.distance.is_need_distance==1){//必填
					if(!(this.$my.check.is_num(this.distance.num)&&this.distance.num>0)){
						this.$my.other.msg({
							type:"warning",
							str:'此路线必填运输距离, 请输入运输距离'
						});
						return;
					}else post_data.set.distance=this.distance.num
				}else if(this.distance.is_need_distance==2){//非必填
					if(this.$my.check.is_num(this.distance.num)&&this.distance.num>0){
						post_data.set.distance=this.distance.num
					}else post_data.set.distance=0
				}else{
					this.$my.other.msg({
						type:"warning",
						str:'请刷新后再试'
					});
					return;
				}

				//货物名称
				if(!this.form.cargo_name.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少货物名称'
					});
					return;
				}else post_data.set.cargo_name=this.form.cargo_name.trim()

				//运费
				if(this.$my.check.is_num(this.form.freight_total)&&this.form.freight_total>0){
					post_data.set.freight_total=this.form.freight_total
				}

				//发货地/到货地
				let case_prov=CodeToText[this.form.case_city[0]];
				let case_city=CodeToText[this.form.case_city[1]];
				let case_county=CodeToText[this.form.case_city[2]];
				let aim_prov=CodeToText[this.form.aim_city[0]];
				let aim_city=CodeToText[this.form.aim_city[1]];
				let aim_county=CodeToText[this.form.aim_city[2]];
				if(!(case_prov&&case_city&&case_county)){
					this.$my.other.msg({
						type:"warning",
						str:'缺少装货地信息'
					});
					return;
				}else{
					post_data.set.case_prov=case_prov
					post_data.set.case_city=case_city
					post_data.set.case_county=case_county
				}
				if(!(aim_prov&&aim_city&&aim_county)){
					this.$my.other.msg({
						type:"warning",
						str:'缺少卸货地信息'
					});
					return;
				}else{
					post_data.set.aim_prov=aim_prov
					post_data.set.aim_city=aim_city
					post_data.set.aim_county=aim_county
				}
				if(this.form.case_addr.trim()){//发货地详细地址
					post_data.set.case_addr=this.form.case_addr.trim()
				}
				if(this.form.aim_addr.trim()){//卸货地详情
					post_data.set.aim_addr=this.form.aim_addr.trim()
				}
				
				//客户
				if(!this.shipper.user_num){
					this.$my.other.msg({
						type:"warning",
						str:'缺少客户信息'
					});
					return;
				}else post_data.set.shipper_num=this.shipper.user_num

				//司机(车主)
				if(!this.truck_owner.user_num){
					this.$my.other.msg({
						type:"warning",
						str:'缺少司机信息'
					});
					return;
				}else{
					post_data.set.truck_owner_num=this.truck_owner.user_num
					post_data.set.driver_num=this.truck_owner.user_num
				}
				
				//货车
				if(!this.truck.truck_plate_num_lock){
					this.$my.other.msg({
						type:"warning",
						str:'缺少货车信息'
					});
					return;
				}else post_data.set.truck_plate_num=this.truck.truck_plate_num_lock
				
				//发车到货时间
				let now_time=new Date().getTime()
				let start_time=null
				let arrived_time=null
				if(this.form.start_time){//发车时间
					start_time=this.form.start_time.getTime();
					if(start_time>now_time){
						this.$my.other.msg({
							type:"warning",
							str:'发车不能晚于当前时间'
						});
						return;
					}
					post_data.set.start_time=(start_time/1000).toFixed(0)
				}
				if(this.form.arrived_time){//到货时间
					arrived_time=this.form.arrived_time.getTime();
					if(!this.form.start_time){
						this.$my.other.msg({
							type:"warning",
							str:'有到货时间时必须先有发车时间'
						});
						return;
					}
					if(arrived_time<=start_time){
						this.$my.other.msg({
							type:"warning",
							str:'到货时间不能早于发车时间'
						});
						return;
					}
					if(arrived_time>now_time){
						this.$my.other.msg({
							type:"warning",
							str:'到货时间不能晚于当前时间'
						});
						return;
					}
					post_data.set.arrived_time=(arrived_time/1000).toFixed(0)
				}

				//上游运单编号
				if(this.form.out_trade_num.trim()){
					post_data.set.out_trade_num=this.form.out_trade_num.trim()
				}

				//组编号1(用户自定义打组)
				if(this.form.group1_num.trim()){
					post_data.set.group1_num=this.form.group1_num.trim()
				}

				//备注
				if(this.form.mark.trim()){
					post_data.set.mark=this.form.mark.trim()
				}

				//提交数据
				this.$my.net.req({
					data:post_data,
					callback:(data)=>{

						//关闭弹出层
						this.is_show_in_Page=false;

						//通知
						this.$emit("sub");
					}
				});
			},

			//结算方式发生变化
			settlement_type_change(){
				switch(this.tord_info.settlement_type){
					case '1'://按重量结算
						this.cargo_weight.is_need_weight=1;
						this.cargo_weight.is_need_weight_text='必填';
						this.cargo_volume.is_need_volume=2;
						this.cargo_volume.is_need_volume_text='非必填';
						this.distance.is_need_distance=2;
						this.distance.is_need_distance_text='非必填';
						break;
					case '2'://按体积结算
						this.cargo_weight.is_need_weight=2;
						this.cargo_weight.is_need_weight_text='非必填';
						this.cargo_volume.is_need_volume=1;
						this.cargo_volume.is_need_volume_text='必填';
						this.distance.is_need_distance=2;
						this.distance.is_need_distance_text='非必填';
						break;
					case '3'://按趟结算
						this.cargo_weight.is_need_weight=2;
						this.cargo_weight.is_need_weight_text='非必填';
						this.cargo_volume.is_need_volume=2;
						this.cargo_volume.is_need_volume_text='非必填';
						this.distance.is_need_distance=1;
						this.distance.is_need_distance_text='必填';
						break;
				}
			},

			//清空本页数据
			clear(){

				//结算方式/货物重量/体积
				this.form.settlement_type=this.tord_info.settlement_type
				this.cargo_weight.num=this.tord_info.cargo_weight
				this.cargo_volume.num=this.tord_info.cargo_volume
				this.distance.num=this.tord_info.distance
				
				//结算方式发生变化
				this.settlement_type_change()

				//货物名称/是否回程/上游运费
				this.form.cargo_name=this.tord_info.cargo_name
				this.form.is_need_return=this.tord_info.is_need_return
				this.form.freight_total=this.tord_info.cost_info.freight_total

				//装货地
				this.form.case_addr=this.tord_info.case_addr
				this.form.case_city=[
					TextToCode[this.tord_info.case_prov].code,
					TextToCode[this.tord_info.case_prov][this.tord_info.case_city].code,
					TextToCode[this.tord_info.case_prov][this.tord_info.case_city][this.tord_info.case_county].code,
				];

				//卸货地
				this.form.aim_addr=this.tord_info.aim_addr
				this.form.aim_city=[
					TextToCode[this.tord_info.aim_prov].code,
					TextToCode[this.tord_info.aim_prov][this.tord_info.aim_city].code,
					TextToCode[this.tord_info.aim_prov][this.tord_info.aim_city][this.tord_info.aim_county].code,
				];

				//客户信息
				this.shipper.tel=this.tord_info.shipper_info.tel;
				this.shipper.user_num=this.tord_info.shipper_info.user_num;
				this.shipper.info=this.tord_info.shipper_info.name+' / '+this.tord_info.shipper_info.company_name;

				//车主信息
				this.truck_owner.tel=this.tord_info.truck_owner_info.tel;
				this.truck_owner.user_num=this.tord_info.truck_owner_info.user_num;
				this.truck_owner.name=this.tord_info.truck_owner_info.name;

				//车辆信息
				this.truck.truck_plate_num=this.tord_info.truck_info.truck_plate_num;
				this.truck.truck_plate_num_lock=this.tord_info.truck_info.truck_plate_num;
				this.truck.truck_info_str=this.tord_info.truck_info.truck_type_name+' / '+this.tord_info.truck_info.truck_type_name2;

				//发到货时间
				this.form.start_time=this.tord_info.start_time>0?new Date(this.tord_info.start_time*1000):null;
				this.form.arrived_time=this.tord_info.arrived_time>0?new Date(this.tord_info.arrived_time*1000):null;

				//其他信息
				this.form.out_trade_num=this.tord_info.out_trade_num
				this.form.group1_num=this.tord_info.group1_num
				this.form.mark=this.tord_info.mark
			},

			//查找货车
			truck_ser(){
				
				//格式判断
				if(!this.$my.check.is_plate_num(this.truck.truck_plate_num)){
					this.$my.other.msg({
						type:"warning",
						str:'缺少车牌号或者格式有误'
					});
					return;
				}

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck',
						ctr:'truck_list_admin',
						truck_plate_num:this.truck.truck_plate_num,
					},
					callback:(data)=>{

						if(data.list.length==0){
							this.$my.other.msg({
								type:"warning",
								str:'查询结果为空'
							});
							return;
						}else var truck_info=data.list[0]

						if(truck_info.status!='2'){
							this.$my.other.msg({
								type:"warning",
								str:'此货车尚未审核通过'
							});
							return;
						}

						this.truck.truck_plate_num_lock=truck_info.truck_plate_num
						this.truck.truck_info_str=truck_info.truck_type_name+' / '+truck_info.truck_type_name2
					}
				});
			},

			//清空货车信息
			truck_clear(){
				this.truck.truck_plate_num_lock=''
				this.truck.truck_plate_num=''
				this.truck.truck_info_str=''
			},

			//查找车主
			truck_owner_ser(){

				//格式判断
				if(!this.$my.check.is_tel(this.truck_owner.tel)){
					this.$my.other.msg({
						type:"warning",
						str:'缺少司机电话号码或者格式有误'
					});
					return;
				}

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_driver',
						ctr:'driver_list_admin',
						user_tel:this.truck_owner.tel,
					},
					callback:(data)=>{

						if(data.list.length==0){
							this.$my.other.msg({
								type:"warning",
								str:'查询结果为空'
							});
							return;
						}else var truck_owner=data.list[0]

						if(truck_owner.status!='2'){
							this.$my.other.msg({
								type:"warning",
								str:'此司机尚未审核通过, 请联系客服'
							});
							return;
						}

						if(truck_owner.user_num==this.shipper.user_num){
							this.$my.other.msg({
								type:"warning",
								str:'司机与货主不能是同一人'
							});
							return;
						}

						this.truck_owner.user_num=truck_owner.user_num
						this.truck_owner.name=truck_owner.user_name
					}
				});
			},

			//清空车主信息
			truck_owner_clear(){
				this.truck_owner.tel=''
				this.truck_owner.name=''
				this.truck_owner.user_num=''
			},

			//查找客户
			shipper_ser(){
				
				//格式判断
				if(!this.$my.check.is_tel(this.shipper.tel)){
					this.$my.other.msg({
						type:"warning",
						str:'缺少客户电话号码或者格式有误'
					});
					return;
				}

				//读取数据
				this.$my.net.req({
					data:{
						mod:'company',
						ctr:'company_list_admin',
						user_tel:this.shipper.tel,
					},
					callback:(data)=>{

						if(data.list.length==0){
							this.$my.other.msg({
								type:"warning",
								str:'查询结果为空'
							});
							return;
						}else var shipper=data.list[0]

						if(shipper.user_num==this.truck_owner.user_num){
							this.$my.other.msg({
								type:"warning",
								str:'司机与货主不能是同一人'
							});
							return;
						}

						this.shipper.user_num=shipper.user_num
						this.shipper.info=shipper.user_name+' / '+shipper.name
					}
				});
			},

			//清空客户信息
			shipper_clear(){
				this.shipper.tel=''
				this.shipper.info=''
				this.shipper.user_num=''
			},
		}
	}
</script>

<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}

	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}

	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	
	.el_inner_width{
		width:94%;
	}
</style>